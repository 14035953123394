import React, { useEffect } from "react";
import * as Icons from "@wix/wix-ui-icons-common";
import {
  Box,
  Button,
  Card,
  WixDesignSystemProvider,
  MarketingPageLayout,
  MarketingPageLayoutContent,
  Page,
  Loader,
  SkeletonGroup,
  SkeletonRectangle,
} from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import { dashboard } from "@wix/dashboard";
import { createClient } from "@wix/sdk";
import { ReactTagManager } from "react-gtm-ts";

function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

const tagManagerArgs = {
  code: "GTM-WZQPMC7",
};

function App() {
  ReactTagManager.init(tagManagerArgs);
  const appId = new URLSearchParams(window.location.search).get("appId");
  const [client, setClient] = React.useState(null as any);
  const [message, setMessage] = React.useState({} as any);
  const [showFade, setShowFade] = React.useState(false);

  const token = new URLSearchParams(window.location.search).get("token");
  const instance = new URLSearchParams(window.location.search).get("instance");
  const [isUpgraded, setIsUpgraded] = React.useState(false);
  const [instanceData, setInstanceData] = React.useState({
    instance: {
      isFree: true,
      availablePlans: [],
      instanceId: "",
    },
  } as any);

  const URLS = {
    editorUrl: `https://www.wix.com/editor/${instanceData?.site?.siteId}`,
    upgradeUrl: `https://www.wix.com/apps/upgrade/${appId}?appInstanceId=${instanceData?.instanceId}`,
  };

  useEffect(() => {
    try {
      if (inIframe()) {
        var c = createClient({
          host: dashboard.host(),
          auth: dashboard.auth(),
          modules: {
            dashboard,
          },
        });
        setClient(c);
      }
    } catch (error) {
      throw error;
    }
    fetch(
      `https://certifiedcode.wixsite.com/custom-dashboard/_functions/appdashboard/${appId}`
    )
      .then((response) => response.json())
      .then((data) => {
        setMessage(data);
        setShowFade(true);
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    fetch(
      `https://certifiedcode.wixsite.com/custom-dashboard/_functions/instance/${appId}`
    )
      .then((response) => response.json())
      .then((data) => {
        setInstanceData(data);
        setShowFade(true);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    // // Check if the iframe has already been loaded on this page session
    // if (sessionStorage.getItem("iframeLoaded")) {
    //   // Hide the current iframe by setting display to none
    //   document.body.style.display = "none"; // Hide the entire body of the iframe content
    // } else {
    //   // Mark the iframe as loaded
    //   sessionStorage.setItem("iframeLoaded", "true");
    // }
    // // clean when unload
    // window.addEventListener("beforeunload", () => {
    //   sessionStorage.removeItem("iframeLoaded");
    // });
  }, []);

  useEffect(() => {
    if (token && message?.redirectUrl) {
      window.location.href = `https://www.wix.com/installer/install?token=${token}&appId=${appId}&redirectUrl=${message.redirectUrl}`;
    }
  }, [token, message]);

  if (token) {
    return (
      <WixDesignSystemProvider features={{ newColorsBranding: true }}>
        <Page height="100vh">
          <Page.Content>
            <Box height={"90vh"} direction="vertical" verticalAlign="middle">
              <Loader text="Loading" />
            </Box>
          </Page.Content>
        </Page>
      </WixDesignSystemProvider>
    );
  }

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Card>
        <MarketingPageLayout
          removeImageHorizontalPadding
          removeImageVerticalPadding
          content={
            <Box height="840px" verticalAlign="middle">
              {message._id ? (
                <MarketingPageLayoutContent
                  title={message.title}
                  content={message.description}
                  actions={
                    <Box gap="SP2">
                      <Button
                        suffixIcon={<Icons.Edit />}
                        disabled={!instanceData?.site?.siteId}
                        as="a"
                        href={URLS.editorUrl}
                        target="_blank"
                      >
                        {"Edit Site"}
                      </Button>
                      <Button
                        suffixIcon={<Icons.PremiumFilled />}
                        disabled={!instanceData?.instanceId}
                        skin={isUpgraded ? "premium-light" : "premium"}
                        as="a"
                        href={URLS.upgradeUrl}
                        target="_blank"
                      >
                        {isUpgraded ? "Manage Plan" : "Upgrade to set live"}
                      </Button>
                    </Box>
                  }
                />
              ) : (
                <MarketingPageLayoutContent
                  title={
                    <SkeletonGroup skin="light">
                      <Box gap="SP2" direction="vertical">
                        <SkeletonRectangle height={"72px"} width={"396px"} />
                      </Box>
                    </SkeletonGroup>
                  }
                  content={
                    <SkeletonGroup skin="light">
                      <Box gap="SP2" direction="vertical">
                        <SkeletonRectangle height={"48px"} width={"396px"} />
                      </Box>
                    </SkeletonGroup>
                  }
                  actions={
                    <Box gap="SP2">
                      <SkeletonGroup skin="light">
                        <SkeletonRectangle height={"36px"} width={"129px"} />
                      </SkeletonGroup>
                      <SkeletonGroup skin="light">
                        <SkeletonRectangle height={"36px"} width={"207px"} />
                      </SkeletonGroup>
                    </Box>
                  }
                />
              )}
            </Box>
          }
          image={
            message._id ? (
              message.image
            ) : (
              <SkeletonGroup skin="light">
                <SkeletonRectangle height={"936px"} width={"100%"} />
              </SkeletonGroup>
            )
          }
        />
      </Card>
    </WixDesignSystemProvider>
  );
}

export default App;
